<template>
  <BaseLayout title="我的邀请">
    <div class="list">
      <van-empty description="暂无记录"></van-empty>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  methods: {
    
  }
}
</script>

<style scoped>
.list {
  margin: 10px;
  min-height: 500px;
  background: #F3F3F3;
  border-radius: 5px;
}
</style>